import axios from 'axios'
import { API_URL } from '@/const'
import { store } from '@/store'

export const postAxiosVidecam = async (item) => {
  const { accessToken } = store.state.app.user
  const headers = {
    Authorization: `Bearer ${accessToken}`
  }
  try {
    const { data } = await axios.post(API_URL.VIDECAM_V1, item, { headers })
    return data
  } catch (e) {
    console.log(e.response?.data)
  }
  return {}
}
