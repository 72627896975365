<template>
  <Videcam v-if="isAdmin" />
</template>

<script>
import Videcam from './videcam'
import { mapGetters } from 'vuex'
import { GETTER_APP } from '@/store/const/type'
export default {
  name: 'index-videcam',
  components: { Videcam },
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters({ isAdmin: GETTER_APP.IS_ADMIN })
  },
  created() {}
}
</script>
