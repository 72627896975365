<template>
  <div class="videcam">
    <main>
      <h1>Добавить камеру</h1>

      <mts-input v-model="key" label="Ключ потока" placeholder="Введите ключ" required />
      <br />

      <mts-input v-model="city" label="Город" placeholder="Введите название" required />
      <br />

      <mts-input v-model="address" label="Адрес" placeholder="Введите название" required />
      <br />

      <mts-button @click="submit" theme="dark">Создать</mts-button>
      <br />
    </main>
  </div>
</template>

<script>
import { APP_ROUTE } from '@/const'
import { postAxiosVidecam } from './func'
export default {
  name: 'videcam',
  components: {},
  data() {
    return {
      key: '',
      city: '',
      address: ''
    }
  },
  methods: {
    async submit() {
      const item = {
        stream: this.key,
        place: this.city,
        description: this.address
      }
      const { id } = await postAxiosVidecam(item)
      await this.$router.push(`/${APP_ROUTE.LIVE}/${id}`)
    }
  },
  computed: {},
  created() {}
}
</script>

<style lang="scss">
@import './styles';
</style>
